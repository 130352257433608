<template>
  <v-container>
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-icon color="primary" left>mdi-badge-account-horizontal</v-icon>
        Equipe
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <ListaEquipe />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Equipe",

  components: {
    ListaEquipe: () => import("./components/ListaEquipe"),
  },
};
</script>

<style lang="scss"></style>
